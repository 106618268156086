<template>
    <div :class="['card', autoWidth ? 'card-block': '',noslider ? 'no-slider': '',typeClass, isHighlighted, address!=''?'has-address':'no-address']" @click="highlight">

        <div class="card-img">
            <div v-if="icon!==null" :alt="subCategory" class="card-img__icon" v-html="icon"></div>
        </div>

        <div class="card-content icon-card">
            <card-share-button class="float-right" :link="linkTo"/>
            <bookmark-button  :parentClass="'card-body-icon'" :content="content" v-if="content!==null"/>
            <span :class="['category', 'h5', typeClass]">{{ subCategory }}</span>
            <div v-if="logo!==''" class="image">
                <img :src="logo" alt="Logo">
            </div>

            <h3 class="title">{{ item.title }}</h3>

            <ul class="card-content__list">
                <li v-if="nextAppointmentStartTime!==null && nextAppointmentToTime!==null">
                    <div class="icon"><span class="material-icons-outlined" aria-hidden="true">schedule</span></div>
                    <div class="info"><span>{{nextAppointmentDate}}, {{ nextAppointmentStartTime }} - {{nextAppointmentToTime}}</span></div>
                </li>
                <li v-if="address !== ''">
                    <div class="icon"><span class="material-icons-outlined" aria-hidden="true">place</span></div>
                    <div class="info do-not-translate">
                        <span>{{institution}}</span>
                        <span class="text-muted" v-html="address"></span>
                    </div>
                </li>

                <li v-if="costs!==null && costs!==''">
                    <div class="icon"><span class="material-icons-outlined" aria-hidden="true">euro_symbol</span></div>
                    <div class="info"><span>{{ costs }}</span></div>
                </li>
            </ul>
        </div>

        <router-link :to="linkTo" :title="linkToTitle" :target="openNewTab" class="btn btn-more mt-auto card-hover">
            Mehr Informationen
            <span class="material-icons-outlined show-ltr" aria-hidden="true">navigate_next</span>
            <span class="material-icons-outlined show-rtl" aria-hidden="true">navigate_before</span>   
        </router-link>

    </div>
</template>

<script>
import { getNextAppointment, isOneOfTheAppointmentsNow, getWeekday, formatTime, getMonth, getNextDateForWday, getReadableDate, getNextDateForWdayAtOrAfterDate } from '@/utils/appointment-helpers';
import { getCategoryIcon, getSubCategory } from '@/utils/lupe-helpers';
import { getFieldValues, getFirstFieldValue, isset } from '@/utils/helpers';
import { bus } from '@/main';


export default {
    name: 'AngebotCard',
    components: {
        CardShareButton: () => import('@/components/controls/CardShareButton.vue'),
        BookmarkButton: () => import('@/components/controls/BookmarkButton'),
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        autoWidth: {
            type: Boolean,
        default: false,
        },
        noslider: {
            type: Boolean,
        default: false,
        },
        target: {
            type: String,
        default: "list",
        },
        openInNewTab: {
            type: Boolean,
        default: false,
        }
    },
    computed: {
      content(){
        if(this.item!=null){
          return this.item;
      }
      return null;
  },
  openNewTab(){
    if(this.openInNewTab) {
        return "_blank";
    }
    return "_self";
},
linkTo(){
    return "/angebote/"+this.item.id;
},
linkToTitle(){
    return "Mehr Informationen zu: "+this.item.title;
},
category(){
    var value = getFirstFieldValue(this.item,'kategorie');
    return value!=null?value:'';
},
costs(){
    var value = getFirstFieldValue(this.item,'kosten');
    return value!=null?value:'';
},
institution(){
    var value = getFirstFieldValue(this.item,'nupian_anbieter');
    if(value!==null){
        return value;
    }

    if(this.item!==null){
        return this.item.institution_title;
    }
    return null;
},
subCategory(){
   return getSubCategory(this.item,this.category);
},
isHighlighted(){
    if(this.item.hasOwnProperty("highlight") && this.item.highlight == true){
        return "highlighted";
    }
    return "not-highlighted"
},
icon(){
    if(this.subCategory !== null){
        return getCategoryIcon(this.subCategory);
    }
    return null;
},
logo(){
    if(this.item!==null){
        var logo = getFirstFieldValue(this.item,'logo');
        if(logo!==null){
            return this.$backendUrl+logo;
        }
    }
    return "";
},
typeClass(){
    return this.category!==null?this.category.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, ''):'';
},
address(){
    var address = getFieldValues(this.item,'adresse');
    if(address!==null){
        var gna = "";
        if(address.hasOwnProperty('street') && isset(address.street)){
            gna = gna+address.street+'<br>';
        }
        if(address.hasOwnProperty('zipcode') && isset(address.zipcode)){
            gna = gna+address.zipcode+', ';
        }
        if(address.hasOwnProperty('city') && isset(address.city)){
            gna = gna+address.city;
        }
        return gna;
    }
    return "";
},
appointments(){
    return getFirstFieldValue(this.item,'appointments');
},
nextAppointment(){
    if(this.date != null){
        return getNextAppointment(this.appointments,this.date);
    }
    return getNextAppointment(this.appointments);
},
nextAppointmentWday(){
    if(this.nextAppointment!=null && this.nextAppointment.wday!=null){
        var day = getWeekday(this.nextAppointment.wday);
        return day.substring(0,2);
    }
    return null;
},
nextAppointmentDate(){
    var date = null;
    if(this.nextAppointment!=null && this.nextAppointment.date!=null){
        date = new Date(this.nextAppointment.date);
    }
    else if(this.nextAppointment!=null && this.nextAppointment.wday!==null){
        if(this.appointments !==null && this.appointments.interval_start !==null){
            var date = new Date(Date.parse(this.appointments.interval_start));
            var date = getNextDateForWdayAtOrAfterDate(this.nextAppointment.wday,date);
        }
        else{
            var date = getNextDateForWday(this.nextAppointment.wday);
        }
    }

    if(date!=null){
        return (getReadableDate(date, "normal"));
    }

    return null;
},
nextAppointmentStartTime(){
    if(this.nextAppointment!=null && this.nextAppointment.time!=null && this.nextAppointment.time.time_from!=null){
        var time = this.nextAppointment.time.time_from;
        return formatTime(time);
    }
    return null;
},
nextAppointmentToTime(){
    if(this.nextAppointment!=null && this.nextAppointment.time!=null && this.nextAppointment.time.time_to!=null){
        var time = this.nextAppointment.time.time_to;
        return formatTime(time);
    }
    return null;
},
nextAppointmentMonthAndYear(){
    if(this.nextAppointment!=null && this.nextAppointment.date!=null){
        var date = new Date(this.nextAppointment.date);
        var month = getMonth(date.getMonth()+1);
        var year = date.getFullYear();
        return month.substring(0,3) + '. '+year;
    }
    return null;
},
},
methods:{
    isset,
    getFieldValues,
    getFirstFieldValue,
    showMerklisteModal () {
        bus.$emit('merkliste-modal', true)
    }, 
    highlight(event){
        if(this.target == "map"){
           this.$emit('highlight',{sender:'card', content: this.item});
       }
   }
}
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card {
    display: flex;
    position: relative;
    width: 100%;
    border: none;
    border-radius: 0px;
    margin-right: 15px;
    overflow: visible;
    margin-top: 40px;
    box-shadow: 1px 2px 10px 1px #00000040;

    &.has-address{
        cursor: pointer;
    }

    .card-img__icon{
        margin: 14px;
        fill: $primary;
    }

    &.Freizeit{
        &.highlighted{
            border: 3px solid $primary;
        }

        .card-img{
            border: 1px solid $primary;
        }

        .h5{  
            color: $primary;
        }

        .card-img__icon{  
            fill: $primary;
        }
    }

    &.BeratungHilfe{
        &.highlighted{
            border: 3px solid $wb-meganta;
        }

        .card-img{
            border: 1px solid $wb-meganta;
        }

        .h5{  
            color: $wb-meganta;
        }

        .card-img__icon{  
            fill: $wb-meganta;
        }
    }

    &.AusbildungWeiterbildung{
        &.highlighted{
            border: 3px solid $wb-jade;
        }

        .card-img{
            border: 1px solid $wb-jade;
        }

        .h5{  
            color: $wb-jade;
        }

        .card-img__icon{  
            fill: $wb-jade;
        }
    }

    &-img {
        position: absolute;
        width: 65px;
        height: 65px;
        border-radius: 100px;
        background-color: $white-color;
        border: 1px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -25px;
        left: 25px;

        img {
            max-width: 40px;
            max-height: 41px;
        }
    }

    &-content {
        padding: 20px;

        .category {
            color: $primary;
            margin-bottom: 20px;
            font-size: 14px;
        }

        .time {
            color: $text-light;
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            margin-bottom: 0;
            font-weight: 600;

            @media (min-width: 992px) {
                font-size: 16px;
            }
        }

        .title {
            font-size: 16px;
            line-height: 24px;
            margin-top: 6px;
            margin-bottom: 12px;
        }

        .image {
            img {
                max-height: 80px;
            }
        }

        .desc {
            height: 68px;
            line-height: 20px;
            font-size: 14px;
            margin-bottom: 10px;
        }

        &__list {
            display: block;
            list-style: none;

            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 5px;
                color: $black-color;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                .icon {
                    span {
                        font-size: 18px;
                        margin-right: 15px;
                        line-height: 24px;
                    }
                }

                .info {
                    display: inline-flex;
                    flex-direction: column;
                }
            }
        }
    }

    &:first-child {
        margin-left: 0px;

        @media (max-width: 991px) {
            margin-left: 0px;
        }
    }

    &-block {
        min-width: 290px;
        max-width: 100%;
        width: 100%;
        margin-right: 0;

        &:first-child {
            margin-left: 0;
        }
    }

    &.no-slider {
        margin-right: 0;
        margin-left: 0px;
    }

}

.h5 {
    font-weight: 600;
}

body[dir="rtl"] {
    .card{
        margin-right: 0;
        margin-left: 15px;

        .card-img {
            left: auto;
            right: 25px;
        }

        .card-content__list {
            li {
                .icon {
                    span {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }
            }
        }

        &:first-child {
            margin-right: 0px;
        }


        &.card-block {
            margin-left: 0;



        }
    }
}


</style>
